<template>
  <div>
    <!--Progress Bar-->
    <v-dialog v-model="progress" max-width="290" persistent hide-overlay>
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          Loading
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- New Payment -->
    <v-dialog v-model="entry.open" scrollable persistent max-width="700">
      <v-card>
        <v-card-title class="secondary white--text headline font-weight-medium">
          New Payment
          <v-spacer />
          <v-btn text icon color="white" @click="entry.open = false">
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="paymentForm" v-model="paymentFormValid" lazy-validation>
            <v-container fluid grid-list-sm>
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-text-field
                        v-model="entry.d_date"
                        label="Date"
                        placeholder=" "
                        persistent-placeholder
                        flat
                        readonly
                        loading="false"
                        class="pb-0"
                      />
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        :value="formatMoney(entry.d_totalAmount)"
                        label="Total"
                        placeholder=" "
                        persistent-placeholder
                        flat
                        readonly
                        loading="false"
                        class="pb-0"
                        :rules="rules.d_totalAmount"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs12 md10>
                      <v-select
                        v-model="entry.fk_paymentProfileID"
                        :items="paymentProfilesStore.paymentProfiles"
                        item-text="d_display"
                        item-value="id"
                        label="Payment Method"
                        placeholder=" "
                        persistent-placeholder
                        clearable
                        clear-icon="fal fa-times-circle"
                        :rules="rules.fk_paymentProfileID"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex v-if="invoicesStore.invoices" xs12 md6>
                  <div class="caption">
                    Open Invoices
                  </div>
                  <v-checkbox
                    v-for="check in invoicesStore.openInvoices"
                    v-model="entry.j_invoicePayments"
                    :label="
                      formatDate(check.d_dateDue) +
                        ' - $' +
                        check.d_amountBalance
                    "
                    :value="check.id"
                    class="mt-2 pr-4"
                    multiple
                    hide-details
                    color="primary"
                    @change="selectInvoice(check.id, check.d_amountBalance)"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn rounded block color="primary" @click="submitPayment()">
            <v-icon small left> fal fa-arrow-alt-down </v-icon>Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container v-if="!progress" fluid fill-height grid-list-lg>
      <v-layout row wrap>
        <!---->
        <v-flex xs12>
          <v-toolbar flat dense color="secondary" dark>
            <v-icon>fal fa-credit-card</v-icon>
            <v-toolbar-title class="pl-2">
              Payments
            </v-toolbar-title>
            <v-spacer />
            <v-btn rounded color="green lighten-1" @click="newPayment()">
              <v-icon small left> fal fa-plus </v-icon>New
            </v-btn>
          </v-toolbar>

          <!-- Filter Toolbar -->
          <v-toolbar flat color="white" class="pt-0">
            <!--  Filter Icon -->
            <v-menu
              v-model="paymentFilter"
              offset-y
              right
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <v-btn rounded color="primary" v-on="on">
                  <v-icon small left> fal fa-filter </v-icon
                  ><span
                    v-if="
                      paymentsStore.filters.id ||
                        paymentsStore.filters.customerID ||
                        paymentsStore.filters.orderId ||
                        paymentsStore.filters.date ||
                        paymentsStore.filters.po ||
                        paymentsStore.filters.jobName ||
                        paymentsStore.filters.status
                    "
                    >Filtered</span
                  ><span v-else>Filter</span>
                </v-btn>
              </template>

              <v-card max-width="400px">
                <v-card-text>
                  <v-container fluid grid-list-sm>
                    <v-layout row wrap>
                      <v-flex
                        v-if="
                          paymentsStore.filters.id ||
                            paymentsStore.filters.customerID ||
                            paymentsStore.filters.orderId ||
                            paymentsStore.filters.date ||
                            paymentsStore.filters.po ||
                            paymentsStore.filters.jobName ||
                            paymentsStore.filters.status
                        "
                        xs12
                      >
                        <v-btn
                          block
                          rounded
                          color="primary"
                          @click="clearFilter()"
                        >
                          <v-icon small left> fal fa-times </v-icon>Clear
                          Filters
                        </v-btn>
                      </v-flex>

                      <v-flex
                        xs12
                        v-if="usersStore.authUser.customers.length > 0"
                      >
                        <v-autocomplete
                          v-model="filterBar.customerID"
                          :items="usersStore.authUser.customers_view"
                          item-text="d_companyName"
                          item-value="id"
                          label="Customer"
                          placeholder=" "
                          persistent-placeholder
                          clearable
                          clear-icon="fal fa-times-circle"
                        />
                      </v-flex>

                      <v-flex xs12>
                        <v-text-field
                          v-model="filterBar.id"
                          label="Payment ID"
                          placeholder=" "
                          persistent-placeholder
                        />
                      </v-flex>
                      <v-flex xs12>
                        <v-menu
                          v-model="menu1"
                          :close-on-content-click="false"
                          box
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="filterBar.date"
                              label="Date"
                              placeholder=" "
                              persistent-placeholder
                              append-icon="fal fa-calendar-alt"
                              v-on="on"
                              @blur="date1 = parseDate(filterBar.date)"
                            />
                          </template>
                          <v-date-picker
                            v-model="date1"
                            color="primary"
                            @input="menu1 = false"
                          />
                        </v-menu>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          v-model="filterBar.orderId"
                          label="Order ID"
                          placeholder=" "
                          persistent-placeholder
                        />
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          v-model="filterBar.po"
                          label="Purchase Order"
                          placeholder=" "
                          persistent-placeholder
                        />
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          v-model="filterBar.jobName"
                          label="Job Name"
                          placeholder=" "
                          persistent-placeholder
                        />
                      </v-flex>
                      <v-flex xs12>
                        <v-autocomplete
                          v-model="filterBar.status"
                          :items="paymentStatus"
                          item-text="name"
                          item-value="id"
                          label="Status"
                          placeholder=" "
                          persistent-placeholder
                        />
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn rounded block color="primary" @click="filter()">
                    <v-icon small left> fal fa-check </v-icon>Submit
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-toolbar>

          <!-- Pagination Toolbar -->
          <pagination
            :show="
              paymentsStore.payments.data &&
              paymentsStore.payments.data.length > 0 &&
              paymentsStore.payments.last_page > 1
                ? true
                : false
            "
            :current-page="paymentsStore.payments.current_page"
            :last-page="paymentsStore.payments.last_page"
            @paginate-event="filter($event)"
          />

          <!-- Payments List -->
          <v-list
            v-if="paymentsStore.payments.data.length > 0"
            three-line
            class="pt-0"
          >
            <template v-for="(item, i) in paymentsStore.payments.data">
              <v-list-item :key="i" @click="goPayment(item.uuid)">
                <v-list-item-avatar v-if="item.payment_method">
                  <v-icon color="primary" medium>
                    {{ item.payment_method.d_icon }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-if="usersStore.authUser.customers.length > 0"
                    class="font-weight-bold"
                  >
                    {{ item.customer.d_companyName }}
                  </v-list-item-title>

                  <v-list-item-title
                    >{{ item.d_paymentNumber }} -
                    {{ formatDate(item.d_date) }}</v-list-item-title
                  >
                  <v-list-item-title class="text--primary">
                    ${{ item.d_totalAmount }}
                  </v-list-item-title>
                  <v-list-item-title class="text--primary">
                    {{ item.payment_method ? item.payment_method.d_name : ""
                    }}<span v-if="item.payment_profile">
                      - {{ item.payment_profile.d_ccLast4 }}</span
                    >
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text
                    :class="
                      'text-xs-right subtitle-1 ' +
                        [
                          item.f_status === 1
                            ? 'green--text lighten-1--text'
                            : 'error--text'
                        ]
                    "
                  >
                    {{ translatePaymentStatus(item.f_status) }}
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
              <v-divider />
            </template>
          </v-list>

          <v-list v-else>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="red--text subtitle-1 text-xs-center">
                  No Payments Exist
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { authorizeHeader } from "@/env";
import { mixin } from "@/mixins/mixin.js";
import Pagination from "@/components/Pagination";

export default {
  name: "Payments",
  components: {
    Pagination
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      paymentsStore: state => state.paymentsStore,
      invoicesStore: state => state.invoicesStore,
      paymentProfilesStore: state => state.paymentProfilesStore,
      alertsStore: state => state.alertsStore
    })
  },
  data() {
    return {
      progress: "",
      paymentFilter: false,
      paymentFormValid: true,
      menu1: "",
      date1: "",
      entry: {
        open: false,
        d_date: "",
        date: "",
        fk_customerID: "",
        fk_paymentProfileID: "",
        fk_paymentMethodID: "",
        d_totalAmount: "",
        d_ccApprovalNumber: "",
        d_ccTransactionID: "",
        d_ccTransactionMessage: "",
        d_ccTransactionResult: "",
        d_ccAddressValidation: "",
        d_ccCVVValidation: "",
        d_customerProfileID: "",
        d_profileID: "",
        j_invoicePayments: []
      },
      filterBar: {
        id: "",
        customerID: "",
        orderId: "",
        date: "",
        po: "",
        jobName: "",
        status: "",
        page: ""
      },
      paymentStatus: [
        {
          id: 1,
          name: "Approved"
        },
        {
          id: 3,
          name: "Declined"
        },
        {
          id: 5,
          name: "Voided"
        }
      ],
      rules: {
        d_totalAmount: [v => !!v || "required"],
        fk_paymentProfileID: [v => !!v || "required"]
      }
    };
  },

  watch: {
    date1() {
      this.filterBar.date = this.formatDate(this.date1);
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.progress = true;

      const user = this.$store.getters["usersStore/getAuthUser"];

      const filters = this.$store.getters["paymentsStore/getFilters"];

      this.filterBar.id = filters.id;
      this.filterBar.customerID = "";
      this.filterBar.date1 = filters.date;
      this.filterBar.orderId = filters.orderId;
      this.filterBar.po = filters.po;
      this.filterBar.jobName = filters.jobName;
      this.filterBar.status = filters.status;

      const data = {
        fk_customerID: user.fk_customerID,
        fk_userID: user.id,
        id: filters.id,
        customerID: filters.customerID,
        date: filters.date,
        orderId: filters.orderId,
        po: filters.po,
        jobName: filters.jobName,
        status: filters.status,
        page: filters.page
      };

      if (!user) {
        this.$router.push({ name: "logout" });
      } else {
        await this.$store.dispatch("paymentsStore/fetchByCustomer", data);
        await this.$store.dispatch(
          "paymentProfilesStore/fetchByCustomerActive",
          data
        );
        this.progress = false;
      }
      this.progress = false;
    },

    async filter(page) {
      this.paymentFilter = false;
      const user = this.$store.getters["usersStore/getAuthUser"];

      const data = {
        fk_customerID: user.fk_customerID,
        fk_userID: user.id,
        id: this.filterBar.id,
        customerID: this.filterBar.customerID,
        date: this.date1,
        orderId: this.filterBar.orderId,
        po: this.filterBar.po,
        jobName: this.filterBar.jobName,
        status: this.filterBar.status,
        page
      };

      this.$store.dispatch("paymentsStore/storeFilters", data);
      await this.$store.dispatch("paymentsStore/fetchByCustomer", data);
    },

    async clearFilter() {
      this.paymentFilter = false;
      const user = this.$store.getters["usersStore/getAuthUser"];

      this.filterBar.id = "";
      this.filterBar.customerID = "";
      this.filterBar.date = "";
      this.filterBar.orderId = "";
      this.filterBar.po = "";
      this.filterBar.jobName = "";
      this.filterBar.status = "";
      this.filterBar.page = 1;

      const data = {
        fk_customerID: user.fk_customerID,
        fk_userID: user.id,
        id: this.filterBar.id,
        customerID: this.filterBar.customerID,
        date: this.filterBar.date,
        orderId: this.filterBar.orderId,
        po: this.filterBar.po,
        jobName: this.filterBar.jobName,
        status: this.filterBar.status,
        page: this.filterBar.page
      };
      this.$store.dispatch("paymentsStore/storeFilters", data);
      await this.$store.dispatch("paymentsStore/fetchByCustomer", data);
    },

    async newPayment() {
      const customer = this.$store.getters["customersStore/getCustomer"];
      let data = {
        fk_customerID: customer.id
      };
      await this.$store.dispatch("invoicesStore/fetchByCustomerOpen", data);
      const invoices = this.$store.getters["invoicesStore/getOpenInvoices"];
      if (invoices.length > 0) {
        const profiles = this.$store.getters[
          "paymentProfilesStore/getPaymentProfiles"
        ];
        if (customer.d_paymentCustomerProfileID && profiles.length > 0) {
          /* Check for default profile */
          if (profiles) {
            for (let i = 0; i < profiles.length; i++) {
              if (profiles[i].f_default === 1) {
                this.entry.fk_paymentProfileID = profiles[0].id;
              }
            }
          } else {
            this.entry.fk_paymentMethodID = "";
          }

          this.entry.fk_customerID = customer.id;
          this.entry.d_customerProfileID = customer.d_paymentCustomerProfileID;
          const today = new Date();
          this.entry.d_date = `${today.getMonth() +
            1}/${today.getDate()}/${today.getFullYear()}`;
          this.entry.date = `${today.getFullYear()}-${today.getMonth() +
            1}-${today.getDate()}`;
          this.entry.d_totalAmount = 0;
          this.entry.d_ccApprovalNumber = "";
          this.entry.d_ccTransactionID = "";
          this.entry.d_ccTransactionMessage = "";
          this.entry.d_ccTransactionResult = "";
          this.entry.d_profileID = "";
          this.entry.j_invoicePayments = [];
          this.entry.open = true;
        } else {
          data = {
            color: "error",
            text:
              "No active payment methods exist.  Add a new payment method under Profile first."
          };
          this.$store.commit("alertsStore/setAlert", data);
        }
      } else {
        data = {
          color: "error",
          text: "There are no open invoices to pay at this time."
        };
        this.$store.commit("alertsStore/setAlert", data);
      }
    },

    selectInvoice(id, amount) {
      const idx = this.entry.j_invoicePayments.map(item => item).indexOf(id);
      const total = this.entry.d_totalAmount * 100;
      let newTotal;
      let displayTotal;
      if (idx === -1) {
        newTotal = (total - amount * 100) / 100;
      } else {
        newTotal = (total + amount * 100) / 100;
      }
      displayTotal = isNaN(newTotal) ? "" : newTotal;
      this.entry.d_totalAmount = displayTotal;
    },

    async createTransactionRequest(
      amount,
      customerProfileId,
      paymentProfileId
    ) {
      const data = {
        createTransactionRequest: {
          merchantAuthentication: {
            name: process.env.VUE_APP_AUTHORIZE_MERCHANTID,
            transactionKey: process.env.VUE_APP_AUTHORIZE_TRANSACTIONKEY
          },
          transactionRequest: {
            transactionType: "authCaptureTransaction",
            amount,
            profile: {
              customerProfileId,
              paymentProfile: {
                paymentProfileId
              }
            }
          }
        }
      };
      try {
        return await axios.post(process.env.VUE_APP_AUTHORIZE_URL, data, {
          headers: authorizeHeader()
        });
      } catch (error) {
        const errorResponse = {
          data: {
            messages: {
              resultCode: "Error",
              message: error
            }
          }
        };
        return errorResponse;
      }
    },

    async submitPayment() {
      if (this.$refs.paymentForm.validate()) {
        await this.$store.dispatch(
          "paymentProfilesStore/read",
          this.entry.fk_paymentProfileID
        );

        const paymentProfile = this.$store.getters[
          "paymentProfilesStore/getPaymentProfile"
        ];

        const payment = await this.createTransactionRequest(
          this.entry.d_totalAmount,
          this.entry.d_customerProfileID,
          paymentProfile.d_profileID
        );
        const check = this.checkAuthorizeRequest(payment);
        /* If response is successful */
        if (check === 1) {
          this.entry.fk_paymentMethodID =
            paymentProfile.fk_paymentMethodID_Internal;
          this.entry.d_profileID = paymentProfile.d_profileID;
          this.entry.d_ccTransactionResult =
            payment.data.transactionResponse.responseCode;
          this.entry.d_ccApprovalNumber =
            payment.data.transactionResponse.authCode;
          this.entry.d_ccTransactionMessage = payment.data;
          this.entry.d_ccTransactionID =
            payment.data.transactionResponse.transId;
          this.entry.d_ccAddressValidation =
            payment.data.transactionResponse.avsResultCode;
          this.entry.d_ccCVVValidation =
            payment.data.transactionResponse.cvvResultCode;
          await this.$store.dispatch("paymentsStore/create", this.entry);
          this.openSnackbar(2, check);
        } else {
          /* ERROR */
          this.openSnackbar(1, check);
        }
        this.entry.open = false;
      }
    },

    openSnackbar(code, message) {
      let data;
      if (code === 1) {
        data = {
          color: "error",
          text: message
        };
      } else if (code === 2) {
        data = {
          color: "green lighten-1",
          text: "Payment processed successfully."
        };
      }
      this.$store.commit("alertsStore/setAlert", data);
    },

    goPayment(uuid) {
      this.$router.push({
        name: "paymentDetail",
        params: { paymentUUID: uuid }
      });
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}

button {
  outline: none;
}
</style>
